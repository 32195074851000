import tympanaLogo from "../logos/tympana-dark.svg";
import {
  AWS_COGNITO_REGION,
  AWS_USER_POOLS_ID,
  AWS_USER_POOLS_WEB_CLIENT_ID,
} from "../utils/environment";
import { IAuthenticator } from "./auth";
import {
  Authenticator as AmplifyAuthenticator,
  Authenticator,
  useAuthenticator,
} from "@aws-amplify/ui-react";
import { Amplify } from "aws-amplify";
import styled, { keyframes } from "styled-components";

const enterContainer = keyframes`
  from {
    opacity: 0;
  }
`;

const Container = styled.div`
  animation: ${enterContainer} 750ms ease;
  display: flex;
  min-height: 100vh;
  padding: 2rem;
`;

const Content = styled.div`
  margin: auto;
  [data-amplify-authenticator] {
    --amplify-components-authenticator-router-border-color: ${(props) =>
      props.theme.color.gray};
    --amplify-components-authenticator-router-box-shadow: 0;
    --amplify-components-heading-3-font-size: var(--amplify-font-sizes-xl);
  }
`;

const Logo = styled.img`
  display: block;
  height: 3.5rem;
  margin: 0 auto 2rem;
  width: auto;
`;

let isConfigured = false;

/**
 * Configure the AWS Amplify / Cognito client
 */
function configure(): void {
  if (isConfigured) {
    return;
  }

  Amplify.configure({
    aws_cognito_region: AWS_COGNITO_REGION,
    aws_mandatory_sign_in: "enable",
    aws_user_pools_id: AWS_USER_POOLS_ID,
    aws_user_pools_web_client_id: AWS_USER_POOLS_WEB_CLIENT_ID,
  });

  isConfigured = true;
}

export const CognitoAuthenticator: IAuthenticator = {
  AuthenticationProvider: ({ children }) => {
    configure();

    return <Authenticator.Provider>{children}</Authenticator.Provider>;
  },

  Authenticator: () => {
    return (
      <Container>
        <Content>
          <Logo src={tympanaLogo} alt="Tympana" />
          <AmplifyAuthenticator hideSignUp={true} />
        </Content>
      </Container>
    );
  },

  useAuth: () => {
    configure();

    const { authStatus, signOut, user } = useAuthenticator((context) => [
      context.authStatus,
      context.signOut,
      context.user,
    ]);

    if (authStatus !== "authenticated") {
      return { isAuthenticated: false, signOut };
    }

    const token = user.getSignInUserSession()?.getAccessToken().getJwtToken();
    const id = user.attributes?.sub;
    const username = user.username;

    if (!id || !token || !username) {
      throw new Error(`Failed to retrieve user information`);
    }

    return {
      isAuthenticated: true,
      signOut,
      user: { id, token, username },
    };
  },
};

import { isDevelopment } from "../../utils/environment";
import {
  key as tympanaApiKey,
  middleware as tympanaApiMiddleware,
  reducer as tympanaApiReducer,
} from "./api/tympana";
import {
  key as annotationsKey,
  reducer as annotationsReducer,
} from "./features/annotations";
import { key as authKey, reducer as authReducer } from "./features/auth";
import {
  key as projectsKey,
  reducer as projectsReducer,
} from "./features/projects";
import { Reducer, combineReducers, configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/dist/query";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";

const reducers = {
  [annotationsKey]: annotationsReducer,
  [authKey]: authReducer,
  [projectsKey]: projectsReducer,
  [tympanaApiKey]: tympanaApiReducer,
};

const combinedReducer = combineReducers<typeof reducers>(reducers);

const rootReducer: Reducer<RootState> = (state, action) => {
  return combinedReducer(state, action);
};

export const store = configureStore({
  devTools: isDevelopment,
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware().concat(tympanaApiMiddleware);
  },
  reducer: rootReducer,
});

export type RootState = ReturnType<typeof combinedReducer>;
export type AppDispatch = typeof store.dispatch;
export const useTypedDispatch = () => useDispatch<AppDispatch>();
export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;

setupListeners(store.dispatch);

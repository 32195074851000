import { IAuthenticator } from "./auth";

export const LocalAuthenticator: IAuthenticator = {
  AuthenticationProvider: ({ children }) => {
    return <>{children}</>;
  },

  Authenticator: () => {
    throw new Error(
      "Unexpected unauthenticated with local development authentication configured"
    );
  },

  useAuth: () => ({
    isAuthenticated: true,
    signOut: () => {},
    user: {
      id: "local1",
      token: "local.token.value",
      username: "local-username",
    },
  }),
};

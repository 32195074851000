import { assertIsDefined } from "./assertions";

function getPreviousSiblings(
  container: Node,
  child: Node,
  previousSiblings: Node[] = []
): Node[] {
  if (container === child) {
    return previousSiblings;
  }

  const parent = child.parentElement;

  if (!parent) {
    throw new Error("Unexpectedly traversed to root node");
  }

  for (const previousSibling of Array.from(parent.childNodes)) {
    if (previousSibling === child) {
      break;
    }

    previousSiblings.push(previousSibling);
  }

  return previousSiblings;
}

function calculateOffset(
  container: Node,
  child: Node,
  relativeOffset: number
): number {
  const previousSiblings = getPreviousSiblings(container, child);

  const previousSiblingOffset = previousSiblings.reduce(
    (runningOffset, previousSibling) =>
      runningOffset + (previousSibling.textContent?.length || 0),
    0
  );

  console.log("previousSiblingOffset", previousSiblingOffset);
  console.log("relativeOffset", relativeOffset);

  return relativeOffset + previousSiblingOffset;
}

export function getCurrentSelection(container: Node): Selection | null {
  const selection = document.getSelection();

  if (!selection) {
    return null;
  }

  if (selection.isCollapsed || selection.rangeCount === 0) {
    return null;
  }

  const range = selection.getRangeAt(0);

  if (
    !container.contains(range.startContainer) ||
    !container.contains(range.endContainer)
  ) {
    return null;
  }

  return selection;
}

export function getSelectionEndOffset(
  container: Node,
  selection: Selection
): number {
  console.log(
    "getSelectionEndOffset",
    calculateOffset(
      container,
      assertIsDefined(selection.focusNode?.parentElement),
      selection.focusOffset
    )
  );
  return calculateOffset(
    container,
    assertIsDefined(selection.focusNode?.parentElement),
    selection.focusOffset
  );
}

export function getSelectionStartOffset(
  container: Node,
  selection: Selection
): number {
  console.log(
    "getSelectionStartOffset",
    calculateOffset(
      container,
      assertIsDefined(selection.anchorNode?.parentElement),
      selection.anchorOffset
    )
  );
  return calculateOffset(
    container,
    assertIsDefined(selection.anchorNode?.parentElement),
    selection.anchorOffset
  );
}
